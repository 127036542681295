<template>

  <section id="seguridad">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-file color-secondary"></i>
          Carga un nuevo documento
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/seguridad/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Cargar documento
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-3 mb-lg-0 input-search">
        <input 
            type="text" 
            placeholder="Buscar documento"
            v-model="search_text"
            class="form-control input-custom"
            @keyup.delete="eraseText()"/>
            <div class="input-search-icon">
              <font-awesome-icon icon="search"/>
            </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <div class="table-container">
          <table 
            v-if="document_list.length>0" 
            class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Tipo de archivo</th>
                <th scope="col">Categoria</th>
                <th scope="col">Fecha de subida</th>
                <th scope="col" class="text-center"> Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(doc, k) in document_list" :key="k"
                :class="doc.id_estado_fk=='1' ? '' : 'disabled'">
                <td class="th th-nombre">
                  <font-awesome-icon
                    v-if="doc.id_estado_fk != '1'"
                    :icon="['fas', 'ban']"/>
                    {{ doc.nombre }}
                </td>
                <td class="th th-tipo-documento">{{ doc.url_documento.split(".")[doc.url_documento.split(".").length - 1]}}</td>
                <td class="th th-categoria">{{ doc.categoria[0].nombre }}</td>
                <td class="th th-fecha-subida">{{ dateFormatymd(doc.fecha_creacion) }}</td>
                <td class="th th-accion text-center">
                  <div 
                    class="dropdown dropdown-custom d-flex justify-content-center" 
                    @click="openOption(doc.id_documento)">
                    <button 
                      class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                      type="button" 
                      data-bs-toggle="dropdown">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                  <ul 
                    class="dropdown-menu"
                    v-click-outside="outOption">
                    <li>
                      <a 
                        @click="handleToggleSwitch(doc)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon
                          class="pe-2"
                          :class="doc.id_estado_fk  == '1' ? 'color-red' : 'color-secondary'"
                          :icon="
                            doc.id_estado_fk == '1'
                              ? ['fas', 'ban']
                              : ['fas', 'check']"/>
                        {{ doc.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                      </a>
                    </li>
                    <li>
                      <a 
                        class="dropdown-item" 
                        :href="doc.url_documento" target="_blank">
                        <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                        Vista previa
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="openEditDocument(doc.id_documento)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="openQuestionDelete(doc)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
                </div>

<!--                   <div class="dots" @click="openOption(doc.id_documento)">
                    <img src="@/assets/img/qiyab.png" alt="" />
                    <div
                      class="options"
                      v-click-outside="outOption"
                      v-if="option == doc.id_documento"
                    >
                      <div
                        class="options-option switch"
                        @click="handleToggleSwitch(doc)"
                      >
                      <font-awesome-icon
                          class="options-option-icon"
                          :class="doc.id_estado_fk == '1' ? 'ban' : 'check'"
                          :icon="doc.id_estado_fk == '1' ? 'ban' : 'check'"
                        ></font-awesome-icon>
                        <div class="options-option-text ">
                          
                        
                          {{ doc.id_estado_fk == "1" ? "Desactivar" : "Activar" }}
                        </div>
                      </div>
                      <div class="options-option">
                        <a :href="doc.url_documento" target="_blank">
                          <font-awesome-icon
                            class="options-option-icon"
                            icon="eye"
                          ></font-awesome-icon>
                          <div class="options-option-text">Vista previa</div>
                        </a>
                      </div>
                      <div
                        class="options-option"
                        @click="openEditDocument(doc.id_documento)"
                      >
                        <font-awesome-icon
                          class="options-option-icon"
                          icon="pen"
                        ></font-awesome-icon>
                        <div class="options-option-text">Editar</div>
                      </div>
                      <div
                        class="options-option"
                        @click="openQuestionDelete(doc)"
                      >
                        <font-awesome-icon
                          class="options-option-icon"
                          icon="trash-alt"
                        ></font-awesome-icon>
                        <div class="options-option-text">Eliminar</div>
                      </div>
                    </div>
                  </div> -->
                </td>
              </tr>
            </tbody>
          </table>

          <div 
           v-if="document_list.length == 0"
            class="alert alert-info mb-0">
            <font-awesome-icon class="pe-2" icon="circle-info"/>
            Agrega tus documentos de seguridad.
          </div>

        </div>
      </div>
    </div>

    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner" />

<!--     <div class="seguridad-list">
      <div class="seguridad-list-container">
      <div class="searcher">
        <div class="searcher-container">
          <input
            type="text"
            placeholder="Buscar documento"
            v-model="search_text"
            @keyup.delete="eraseText()"
          />
          <div class="icon">
            <font-awesome-icon icon="search"></font-awesome-icon>
          </div>
        </div>
      </div>
      <div class="tbl-container bdr">
        <table v-if="document_list.length>0" class="table table-borderless">
          <thead class="sticky-top bg-white">
            <tr>
              <th class="name ps-4" scope="col">Nombre</th>
              <th scope="col">Tipo de archivo</th>
              <th scope="col">Categoria</th>
              <th scope="col">Fecha de subida</th>
              <th class="last-child" scope="col"> Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(doc, k) in document_list" :key="k"  :class="doc.id_estado_fk=='1'?'active':'disabled'">
              <td class="name ps-4">
                <font-awesome-icon
                        class="name-icon ban"
                        v-if="doc.id_estado_fk != '1'"
                        :icon="['fas', 'ban']"
                      ></font-awesome-icon>
                      {{ doc.nombre }}</td>
              <td  class="alinear">
                {{
                  doc.url_documento.split(".")[
                    doc.url_documento.split(".").length - 1
                  ]
                }}
              </td>
              
              <td class="alinear">
                {{ doc.categoria[0].nombre }} 
              </td>
              <td class="alinear">{{ dateFormatymd(doc.fecha_creacion) }}</td>
              <td class="last-child">
                <div class="dots" @click="openOption(doc.id_documento)">
                  <img src="@/assets/img/qiyab.png" alt="" />
                  <div
                    class="options"
                    v-click-outside="outOption"
                    v-if="option == doc.id_documento"
                  >
                    <div
                      class="options-option switch"
                      @click="handleToggleSwitch(doc)"
                    >
                    <font-awesome-icon
                        class="options-option-icon"
                        :class="doc.id_estado_fk == '1' ? 'ban' : 'check'"
                        :icon="doc.id_estado_fk == '1' ? 'ban' : 'check'"
                      ></font-awesome-icon>
                      <div class="options-option-text ">
                        
                      
                        {{ doc.id_estado_fk == "1" ? "Desactivar" : "Activar" }}
                      </div>
                    </div>
                    <div class="options-option">
                      <a :href="doc.url_documento" target="_blank">
                        <font-awesome-icon
                          class="options-option-icon"
                          icon="eye"
                        ></font-awesome-icon>
                        <div class="options-option-text">Vista previa</div>
                      </a>
                    </div>
                    <div
                      class="options-option"
                      @click="openEditDocument(doc.id_documento)"
                    >
                      <font-awesome-icon
                        class="options-option-icon"
                        icon="pen"
                      ></font-awesome-icon>
                      <div class="options-option-text">Editar</div>
                    </div>
                    <div
                      class="options-option"
                      @click="openQuestionDelete(doc)"
                    >
                      <font-awesome-icon
                        class="options-option-icon"
                        icon="trash-alt"
                      ></font-awesome-icon>
                      <div class="options-option-text">Eliminar</div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="document_list.length==0" class="table table-borderless" >
          <thead class="sticky-top bg-white">
            <tr>
                <th scope="col">Agrega tus documentos de seguridad </th>
            </tr>
          </thead>
        </table>
      </div>
    </div> 
  </div> -->
</section>

</template>

<script>
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  import {} from "@/apollo/queries";
  import {
    DELETE_SAFETY_DOC,
    CHANGE_SAFETY_DOC_STATUS,
  } from "@/apollo/mutations";
  import { mapActions, mapState } from "vuex";
  import Spinner from "../Spinner.vue";

  export default {
    components: { Question, Status, Spinner },
    data() {
      return {
        search_text: "",
        open_question_modal: false,
        question_modal_msg: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        toggle_switch_btn: false,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        safety_module_id: "9",
        categories_list: [],
        document_list: [],
        show_spinner: false,
        doc_to_delete: null,
        no_results: false,
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        modulos: this.$ls.get("modules"),
        option: "",
      };
    },
    mounted() {
      this.getDocuments();
    },
    computed: {
      ...mapState("docsModule", ["safety_docs_list"]),
      count() {
        return this.$store.state.docsModule.safety_docs_list.length;
      },
  },
    watch: {
      search_text: function () {
        if (this.search_text.length >= 3) {
          var search = this.search_text.toLowerCase();
          let search_result = this.safety_docs_list.filter((doc) =>
            doc.nombre.toLowerCase().includes(search)
          );
          if (search_result.length > 0) {
            this.document_list = [];
            search_result.forEach((item) => {
              this.document_list.push(item);
              this.no_results = false;
            });
          } else {
            this.document_list = [];
            this.no_results = true;
          }
        }
      },
      count() {
        this.show_spinner = false;
        this.document_list = this.safety_docs_list;
      },
    },
    methods: {
      ...mapActions("docsModule", ["getDocsList", "deleteDocAction", "editDocAction"]),
      openQuestionDelete(doc) {
        this.doc_to_delete = doc;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estas seguro de eliminar este protocolo?";
      },
      cancelQuestion() {
        this.open_question_modal = false;
      },
      // eliminar documento
      acceptQuestion() {
        this.show_spinner = true;
        this.$apollo
          .mutate({
            mutation: DELETE_SAFETY_DOC,
            variables: {
              id_documento: this.doc_to_delete.id_documento,
              id_empresa: this.id_empresa,
              id_eliminador: this.$ls.get("user").id_usuario,
            },
          })
          .then((res) => {
            var resp = res.data.eliminarDocumento;
            this.show_spinner = false;
            if (resp.id_documento) {
              this.deleteDocAction(resp.id_documento);
              setTimeout(() => {
                this.document_list = this.safety_docs_list;
              }, 100);
              this.open_question_modal = false;
              this.$toast.open({
                message:'Documento eliminado correctamente.',
                type:'success',
                duration:6000,
                position:'top-right'
              });
            }
          })
          .catch((err) => {
            console.log("Error eliminar documento:", err);
            this.show_spinner = false;
            this.modal_status = false;
            this.$toast.open({
              message:'Ocurrió un error al eliminar el documento, intentalo nuevamente.',
              type:'error',
              duration:6000,
              position:'top-right'
            });
          });
      },
      closeStatus() {
        this.open_question_modal = false;
        this.open_modal_status = false;
      },
      openEditDocument(idDoc) {
        this.$router
          .push({
            name: "seguridad-edit",
            params: { id: idDoc },
          })
          .catch(() => {
            return;
          });
      },
      handleToggleSwitch(doc) {
        if (doc.id_estado_fk == "1") {
          this.changeDocStatus("3", doc.id_documento);
        } else {
          this.changeDocStatus("1", doc.id_documento);
        }
      },
      getDocuments(text) {
        console.log(text);
        if(text == undefined) text = '';
        this.show_spinner = true;
        this.document_list = [];
        var variables_search = {
          id_empresa: this.id_empresa,
          id_categoria: "0", //Categoría: "Todas"
          id_modulo: this.safety_module_id,
          plataforma: "2", //Web
          nombre: text,
        };
        this.getDocsList(variables_search);
        setTimeout(() => {
          this.show_spinner = false;
          this.document_list = this.safety_docs_list;
        }, 100);
      },
      changeDocStatus(status, id) {
        this.show_spinner = true;
        var success_msg = status == "1" ? "activado" : "desactivado";
        var err_msg = status == "1" ? "activar" : "desactivar";

        this.$apollo
          .mutate({
            mutation: CHANGE_SAFETY_DOC_STATUS,
            variables: {
              id_documento: id,
              id_empresa: this.id_empresa,
              id_modificador: this.$ls.get("user").id_usuario,
              estado: status,
            },
          })
          .then((res) => {
            var resp = res.data.cambiarEstadoDocumento;
            this.show_spinner = false;
            if (resp.id_documento) {
              resp.id_modulo = this.safety_module_id;
              // console.log(resp);
              this.editDocAction(resp);
              this.open_question_modal = false;
              this.open_modal_status = true;
              this.modal_status_msg = `${
                "Documento " + success_msg + " con éxito"
              }`;
              this.modal_status = true;
            }
          })
          .catch((err) => {
            console.log("err", err);
            this.show_spinner = false;
            this.modal_status = false;
            this.open_modal_status = true;
            this.modal_status_msg = `${
              "No se ha podido " + err_msg + " el documento, intenta nuevamente"
            }`;
          });
      },
      eraseText() {
        if (this.search_text.length == 0) {
          this.document_list = this.safety_docs_list;
          this.no_results = false;
        }
      },
      handleDate(fecha) {
        var date = new Date(fecha);
        var day = date.getDate();
        var month = date.getMonth();

        if (day < 10) {
          day = "0" + day.toString();
        }

        return day + "/" + month;
      },

      //abrir opciones
      openOption(id) {
        setTimeout(() => {
          this.option = id;
        }, 10);
      },
      outOption() {
        if (this.option != "") {
          this.option = "";
        }
      },
    },
  };
</script>
